export const initAnalytics = (contentId, siteId) => {
    if (typeof window === 'undefined') {
        global.window = {}
    }
    (function (window) {
        (function (w, d, s, e, t) {
            w.AnalyticsGJC = w.AnalyticsGJC || {}; w.AnalyticsGJC.filaAnalyticsGJC = w.AnalyticsGJC.filaAnalyticsGJC || [];
            e = d.createElement(s); e.type = 'text/java' + s; e.async = 'async'; e.src = 'https://s3-sa-east-1.amazonaws.com/analytics-gjc/analytics-gjc-min.js';
            t = d.getElementsByTagName(s)[0]; t.parentNode.insertBefore(e, t);
        })(window, document, 'script');

        var gjcJsonPageViewObj = {};

        window.AnalyticsGJC.filaAnalyticsGJC.push(['definirContentId', contentId]);
        window.AnalyticsGJC.filaAnalyticsGJC.push(['definirSiteId', siteId]);
    })(window);
}

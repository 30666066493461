import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    selectCategoria,
    fetchAnunciosList
} from "./../../actions";
import FilterSelect from "../filtro-select/FiltroSelect";
import FiltroCheckbox from "../filtro-checkbox/FiltroCheckbox";
import SelectTag from "../select-tag/select-tag";
import { searchFilterList } from "./../../resources/mockups/filtro-mockup";
import { StringUtils } from "./../../resources/methods/StringUtils";

// Styles
import {
    FilterContainer,
    FilterInput,
    FilterLabel,
    Filter,
    FilterApplyBtn,
    SelectButton,
    SelectButtonContainer,
    FilterDualItemContainer,
    FilterAutocomplete
} from "./../commons/filter-styled";

// Colors
import { Theme } from "./../commons/vitrine-colors";

import { UrlsClassiUtils } from "./../../resources/methods/UrlsClassiUtils";
import Axios from "axios";

const vitrineType = process.env.VITRINE_TYPE.toLowerCase();
class FiltroBusca extends Component {
    constructor(props) {
        super(props);

        this.state = {
            aceitaFinanciamento: null,
            vitrine: "imoveis",
            defaultTexto: this.props.defaultTexto,
            inputValue: "",
            categoryFilter: {},
            presetFiltro: {},
            vlrMaximo: "",
            vlrMinimo: "",
            query: props.presetFiltro ? props.presetFiltro.query : "",
        };

        this.cityFilterRef = React.createRef();
        this.tipoMarcaRef = React.createRef();
        this.especieModeloRef = React.createRef();
    }

    componentDidMount() { }

    selectAnunciante = value => {
        this.props.handleFilter("anunciante", (value ? parseInt(value.valor) : null));
    };

    handleInput = e => {
        let newState = { [e.target.name]: e.target.value };

        //Condição especial para o input do tipo palavra chave, garantido fim do preset de filtros no primeiro input manual.
        let presetFiltro = null;
        if (this.state.presetFiltro.query && e.target.name === "query") {
            presetFiltro = Object.assign(presetFiltro ? presetFiltro : {}, {
                query: ""
            });
        }

        if (presetFiltro) {
            newState["presetFiltro"] = Object.assign(
                this.state.presetFiltro,
                presetFiltro
            );
        }

        this.setState(newState);
    };

    handleSelect = (key, value) => {
        this.props.handleFilter(key, value);
    };

    filterByBtn = name => {
        this.props.handleFilter(name, this.state[name]);
    };

    handleAceitaTroca = (key, value) => {
        this.props.handleFilter(key, value);
        this.setState({ aceitaTroca: value });
    };

    inputChange = () => {
        this.setState({
            inputValue: event.target.value
        });
    };

    componentDidUpdate(prevProps) {
        const { searchFilterData, presetFiltro } = this.props;

        // debugger;

        //TODO: Aviliar necessidade de deep check entre searchFilterData
        if (presetFiltro) {
            let estado = null;
            let cidade = null;
            let categoria = null;
            let query = null;

            if (searchFilterData.estados && presetFiltro.estado) {
                let indexEstado = searchFilterData.estados.findIndex(function (o) {
                    return o.descricao.toLowerCase() === presetFiltro.estado;
                });
                estado = searchFilterData.estados[indexEstado];
            }
            if (searchFilterData.cidades && presetFiltro.cidade) {
                let indexCidade = searchFilterData.cidades.findIndex(function (o) {
                    return (
                        StringUtils.cleanString(o.descricao) ===
                        presetFiltro.cidade
                    );
                });
                cidade = searchFilterData.cidades[indexCidade];
            }
            if (searchFilterData.categorias && presetFiltro.categoria) {
                let indexCategoria = searchFilterData.categorias.findIndex(function (o) {
                    return (StringUtils.cleanString(o.valor) === presetFiltro.categoria);
                });

                categoria = searchFilterData.categorias[indexCategoria];
            }
            if (presetFiltro.query) {
                query = presetFiltro.query;
            }

            let presetFiltroValues = {};

            Object.assign(presetFiltroValues, estado ? { estado: estado } : null);
            Object.assign(presetFiltroValues, cidade ? { cidade: cidade } : null);
            Object.assign(presetFiltroValues, categoria ? { categoria: categoria } : null);
            Object.assign(presetFiltroValues, query ? { query: query } : null);

            this.setState(
                Object.assign(
                    { presetFiltro: presetFiltroValues }
                )
            );
        }
    }

    // TODO: melhorar busca de filtro
    handleFilterPath = (key, value) => {
        this.props.handleFilter(key, value);

        switch (key) {
            case "estado":
                this.cityFilterRef.current.resetFilter();
                break;
            case "categoria":
                this.tipoMarcaRef.current.resetFilter();
                this.especieModeloRef.current.resetFilter();
                break;
            case "tipoMarca":
                this.especieModeloRef.current.resetFilter();
                break;
        }
    };

    filterArrayValor = () => {
        const { vlrMaximo, vlrMinimo } = this.state;

        if (
            vlrMaximo != "" &&
            vlrMinimo != "" &&
            parseFloat(vlrMaximo) < parseFloat(vlrMinimo)
        ) {
            this.setState({ vlrMinimo: vlrMaximo, vlrMaximo: vlrMinimo });
            this.props.handleFilter(
                ["vlrMinimo", "vlrMaximo"],
                [vlrMaximo, vlrMinimo]
            );
        } else {
            this.props.handleFilter(
                ["vlrMinimo", "vlrMaximo"],
                [this.state.vlrMinimo, this.state.vlrMaximo]
            );
        }
    };

    filterArrayArea = () => {
        const { areaMaxima, areaMinima } = this.state;

        if (
            areaMaxima != "" &&
            areaMinima != "" &&
            parseFloat(areaMaxima) < parseFloat(areaMinima)
        ) {
            this.setState({ areaMinima: areaMaxima, areaMaxima: areaMinima });
            this.props.handleFilter(
                ["areaMinima", "areaMaxima"],
                [areaMaxima, areaMinima]
            );
        } else {
            this.props.handleFilter(
                ["areaMinima", "areaMaxima"],
                [this.state.areaMinima, this.state.areaMaxima]
            );
        }
    };

    getAnuncianteOptions = async inputValue => {
        if (!inputValue || !(typeof inputValue === "string")) {
            return [];
        }

        const response = await Axios.get(
            `${process.env.API_URL}/vitrine-imoveis/nome-fantasia/${inputValue}`
        ).then(response => {
            return response.data;
        });

        return response;
    };

    render() {
        const { searchFilterData } = this.props;
        let query = this.state.query || this.state.presetFiltro.query;
        if (!query) {
            query = "";
        }

        return (
            <FilterContainer direction="column" busca>
                {/* O que você procura */}
                <Filter direction="column" vitrinecolor={Theme[vitrineType].main} busca>
                    <FilterLabel vitrinecolor={Theme[vitrineType].main} busca>O que você procura?</FilterLabel>
                    <FilterInput
                        busca
                        name={searchFilterList.query.name}
                        placeholder={searchFilterList.query.placeholder}
                        value={query}
                        key={searchFilterList.query.filterName}
                        vitrinecolor={Theme[vitrineType].main}
                        onChange={this.handleInput}
                        onKeyPress={this.keyPressed}
                    />

                    <FilterApplyBtn vitrinecolor={Theme[vitrineType].main} onClick={() => this.filterByBtn(searchFilterList.query.name)}>Aplicar</FilterApplyBtn>
                </Filter>

                {/* SUBSEÇÃO / TIPOMARCA / SUBCATEGORIA */}
                <Filter direction="column" vitrinecolor={Theme[vitrineType].main} busca>
                    {/* Subseçao */}
                    <FilterLabel vitrinecolor={Theme[vitrineType].main} busca>
                        {searchFilterList.subsecao.label}
                    </FilterLabel>
                    <FilterSelect
                        name={searchFilterList.subsecao.name}
                        placeholder={searchFilterList.subsecao.placeholder}
                        options={searchFilterData.categorias}
                        filterName={searchFilterList.subsecao.filterName}
                        handleFilterPath={this.handleFilterPath}
                        defaultValue={this.state.presetFiltro.categoria}
                    />

                    {/* Tipo marca */}
                    <FilterLabel vitrinecolor={Theme[vitrineType].main} busca>
                        {searchFilterList.tipoMarca.label}
                    </FilterLabel>
                    <FilterSelect
                        name={searchFilterList.tipoMarca.name}
                        placeholder={searchFilterList.tipoMarca.placeholder}
                        options={searchFilterData.tipoMarca ? searchFilterData.tipoMarca : undefined}
                        filterName={searchFilterList.tipoMarca.filterName}
                        handleFilterPath={this.handleFilterPath}
                        isDisabled={searchFilterData.tipoMarca ? false : true}
                        ref={this.tipoMarcaRef}
                    />

                    {/* Especie modelo */}
                    <FilterLabel vitrinecolor={Theme[vitrineType].main} busca>
                        {searchFilterList.especieModelo.label}
                    </FilterLabel>
                    <FilterSelect
                        name={searchFilterList.especieModelo.name}
                        placeholder={searchFilterList.especieModelo.placeholder}
                        options={searchFilterData.especieModelo ? searchFilterData.especieModelo : undefined}
                        filterName={searchFilterList.especieModelo.filterName}
                        handleFilterPath={this.handleFilterPath}
                        isDisabled={searchFilterData.especieModelo ? false : true}
                        ref={this.especieModeloRef}
                    />
                </Filter>

                {/*  ESTADO/CIDADE */}
                <Filter
                    direction="column"
                    vitrinecolor={Theme[vitrineType].main}
                    busca
                >
                    {/* ESTADO */}
                    <React.Fragment>
                        <FilterLabel
                            vitrinecolor={Theme[vitrineType].main}
                            busca
                        >
                            {searchFilterList.state.label}
                        </FilterLabel>
                        <FilterSelect
                            name={searchFilterList.state.name}
                            placeholder={searchFilterList.state.placeholder}
                            options={searchFilterData.estados}
                            filterName={searchFilterList.state.filterName}
                            handleFilterPath={this.handleFilterPath}
                            defaultValue={this.state.presetFiltro.estado}
                        />
                    </React.Fragment>

                    {/* CIDADE */}
                    <React.Fragment>
                        <FilterLabel
                            vitrinecolor={Theme[vitrineType].main}
                            busca
                        >
                            {searchFilterList.city.label}
                        </FilterLabel>
                        <FilterSelect
                            name={searchFilterList.city.name}
                            placeholder={searchFilterList.city.placeholder}
                            options={
                                searchFilterData.cidades
                                    ? searchFilterData.cidades
                                    : undefined
                            }
                            filterName={searchFilterList.city.filterName}
                            handleFilterPath={this.handleFilterPath}
                            isDisabled={searchFilterData.cidades ? false : true}
                            defaultValue={this.state.presetFiltro.cidade}
                            ref={this.cityFilterRef}
                        />
                    </React.Fragment>
                </Filter>

                {/* TIPO DE NEGOCIAÇÃO / ACEITA TROCA */}
                <Filter
                    direction="column"
                    vitrinecolor={Theme[vitrineType].main}
                    busca
                >
                    {/* Tipo de negociação */}
                    <FilterLabel vitrinecolor={Theme[vitrineType].main} busca>
                        {searchFilterList.tipoNegociacao.label}
                    </FilterLabel>

                    <FilterSelect
                        name={searchFilterList.tipoNegociacao.name}
                        placeholder={searchFilterList.tipoNegociacao.placeholder}
                        options={searchFilterData.tipoNegociacao ? searchFilterData.tipoNegociacao : undefined}
                        filterName={searchFilterList.tipoNegociacao.filterName}
                        handleFilterPath={this.handleFilterPath}
                    />

                    {/* Aceita troca */}
                    <FilterLabel vitrinecolor={Theme[vitrineType].main} busca>
                        {searchFilterList.aceitaTroca.label}
                    </FilterLabel>

                    <SelectButtonContainer busca>
                        <SelectButton
                            selected={this.state.aceitaTroca}
                            vitrinecolor={Theme[vitrineType].main}
                            onClick={() => this.handleAceitaTroca(searchFilterList.aceitaTroca.filterName, true)}
                            busca="true"
                            bordercolor={Theme[vitrineType].main}
                        >
                            Sim
                        </SelectButton>

                        <SelectButton
                            selected={!this.state.aceitaTroca}
                            vitrinecolor={Theme[vitrineType].main}
                            onClick={() => this.handleAceitaTroca(searchFilterList.aceitaTroca.filterName, false)}
                            busca="true"
                            bordercolor={Theme[vitrineType].main}
                        >
                            Não
                        </SelectButton>
                    </SelectButtonContainer>
                </Filter>

                {/* Preço minimo e maximo */}
                <Filter
                    direction="column"
                    vitrinecolor={Theme[vitrineType].main}
                    busca
                >
                    <FilterLabel vitrinecolor={Theme[vitrineType].main} busca>
                        Qual a faixa de preço procura?
                    </FilterLabel>

                    <FilterDualItemContainer>
                        <FilterInput
                            placeholder={searchFilterList.vlrMinimo.placeholder}
                            value={this.state.vlrMinimo}
                            name={searchFilterList.vlrMinimo.filterName}
                            vitrinecolor={Theme[vitrineType].main}
                            onChange={this.handleInput}
                            busca
                        />
                        <span>a</span>
                        <FilterInput
                            placeholder={searchFilterList.vlrMaximo.placeholder}
                            value={this.state.vlrMaximo}
                            name={searchFilterList.vlrMaximo.filterName}
                            vitrinecolor={Theme[vitrineType].main}
                            onChange={this.handleInput}
                            busca
                        />
                    </FilterDualItemContainer>

                    <FilterApplyBtn
                        vitrinecolor={Theme[vitrineType].main}
                        onClick={this.filterArrayValor}
                    >
                        Aplicar
                    </FilterApplyBtn>
                </Filter>
            </FilterContainer>
        );
    }
}

const mapStateToProps = store => ({
    selectedCategoria: store.selectCategoria.selectedCategoria
});

export default connect(
    mapStateToProps,
    { fetchAnunciosList }
)(FiltroBusca);

import {
    FETCH_CATEGORIES_BY_SECTION,
    FETCH_ANUNCIOS_LIST,
    FETCH_ANUNCIO_BY,
    FETCH_CURRENT_USER,
    DELIVER_DEFAULT_USER,
    FETCH_CATEGORIES_LIST,
    FETCH_MOTIVOS_LIST,
    FETCH_USER_INFO,
    FETCH_ANUNCIANTE_INFO,
    FETCH_RELACIONADOS
} from "./types";

import store from "./../../helpers/createStore";
import axios from "axios";

import { relacionadosMockup } from './../resources/mockups/relacionados-mockup';

// Página de busca
export const fetchAnunciosList = filtro => (dispatch, getState, api) => {
    let header = null;

    if (typeof window !== "undefined") {
        if (window.keycloak && window.keycloak.token) {
            header = {
                Authorization: `Bearer ${window.keycloak.token}`
            };
        }
    }

    const response = axios.post(`${process.env.API_URL}/vitrine-agro/consultaVitrineAgro`, filtro, { headers: header ? header : {} })
        .then(response => {
            if (response.data) {
                let anuncioList = response.data;

                if (anuncioList.categorias) {
                    anuncioList.categorias.unshift({
                        descricao: "Todos",
                        valor: null
                    });
                }

                if (anuncioList.estados) {
                    anuncioList.estados.unshift({
                        descricao: "Todos",
                        valor: null
                    });
                }

                if (anuncioList.cidades) {
                    anuncioList.cidades.unshift({
                        descricao: "Todos",
                        valor: null
                    });
                }

                if (anuncioList.tipoNegociacao) {
                    anuncioList.tipoNegociacao.unshift({
                        descricao: "Todos",
                        valor: null
                    });
                }

                dispatch({
                    type: FETCH_ANUNCIOS_LIST,
                    payload: {
                        ...anuncioList,
                        ...{ filtroSetup: filtro }
                    }
                });

                return anuncioList;
            }
        });

    return response;
};

// Página de busca
export const selectCategoria = value => ({
    type: CLICK_UPDATE_ANUNCIO,
    selectedCategoria: value
});

// Página do anúncio
export const fetchAnunciosBy = anuncioId => async (dispatch, getState, api) => {
    let requestContext = undefined;

    let header = null;

    if (typeof window !== "undefined") {
        if (window.keycloak && window.keycloak.token) {
            header = {
                Authorization: `Bearer ${window.keycloak.token}`
            };
        }
    }

    await api
        .get(`${process.env.API_URL}/anuncio-digital/find/${anuncioId}`, {
            headers: header ? header : {}
        })
        .then(response => {
            dispatch({
                type: FETCH_ANUNCIO_BY,
                payload: response.data
            });
        })
        .catch(error => {
            requestContext = Object.assign(
                {},
                {
                    error: error.response.data
                }
            );
        });

    return requestContext; //Somente em caso de erro que há retorno de objeto
};

export const fetchAnunciosRelacionados = filtro => async (dispatch, getState, api) => {
    let requestContext = undefined;

    const { idAnuncio, qtdAnuncios } = filtro;

    await axios.get(`${process.env.API_URL}/vitrine-agro/consultarAnunciosSimilares?idAnuncio=${idAnuncio}&qtdAnuncios=${qtdAnuncios}`)
        .then(response => {
            dispatch({
                type: FETCH_RELACIONADOS,
                payload: response.data
            })
        })

    return requestContext;
};

export const sendContato = contatoMsg => (dispatch, getState, api) =>
    new Promise((resolve, reject) => {
        const { mensagem } = contatoMsg;

        axios
            .post(`${process.env.API_URL}/vitrine-agro/sendMessage`, mensagem)
            .then(response => {
                resolve();
            })
            .catch(error => {
                reject(error);
            });
    });

export const getUserInfo = () => async (dispatch, getState, api) => {
    //Tal requisição somente é para ocorrer client side e logado.
    if (typeof window !== "undefined") {
        if (window.keycloak && window.keycloak.token) {
            await axios
                .get(`${process.env.API_URL}/auth/userinfo`, {
                    headers: {
                        Authorization: `Bearer ${window.keycloak.token}`
                    }
                })
                .then(response => {
                    dispatch({
                        type: FETCH_USER_INFO,
                        payload: response.data
                    });
                })
                .catch(error => {
                    requestContext = Object.assign(
                        {},
                        {
                            error: error.response.data
                        }
                    );
                });
        }
    }
};

export const fetchCategoriesBySection = () => async (
    dispatch,
    getState,
    api
) => {
    const res = await api.get("/categoriessection");

    dispatch({
        type: FETCH_CATEGORIES_BY_SECTION,
        payload: res
    });
};

export const fetchCategories = secao => (dispatch, getState, api) => {
    const categorias = axios.get(`${process.env.API_URL}/vitrine-agro/consultaCategorias`)
        .then(response => {
            if (response.data.length > 0) {
                let categorias = response.data;

                categorias.unshift({
                    descricao: "Todos",
                    valor: null
                });

                dispatch({
                    type: FETCH_CATEGORIES_LIST,
                    payload: categorias
                });

                return categorias;
            }
        });

    return categorias;
};

export const fetchMotivos = () => (dispatch, getState, api) => {
    axios
        .get(`${process.env.API_URL}/vitrine-agro/findMotivosDenuncia`)
        .then(response => {
            if (response.data.length > 0) {
                dispatch({
                    type: FETCH_MOTIVOS_LIST,
                    payload: response.data
                });
            }
        });
};

export const getAnuncianteInfo = anuncianteId => async (dispatch, getState, api) => {
    axios.get(`${process.env.API_URL}/vitrine-agro/anunciantes/{id}?id=${anuncianteId}`)
        .then(
            response => {
                dispatch({
                    type: FETCH_ANUNCIANTE_INFO,
                    payload: response.data
                })
            },
            error => {
                throw error;
            }
        )
};

// Authentication
export const getUser = () => async (dispatch, getState, api) => {
    await api
        .get("/accounts")
        .then(response => {
            dispatch({
                type: FETCH_CURRENT_USER,
                payload: response.data
            });
        })
        .catch(err => {
            // dispatch({ type: DELIVER_DEFAULT_USER });
            throw new Error(err);
        });
};

export const userLogout = () => async (dispatch, getState, api) => {
    dispatch({ type: DELIVER_DEFAULT_USER });
    await api.get("/logout");
};

import React, { Component } from 'react';
import { FilterCheckBox, FilterCheckBoxInput } from '../commons/filter-styled';
import { Theme } from './../commons/vitrine-colors';

const vitrineType = process.env.VITRINE_TYPE.toLowerCase();

export default class FiltroCheckbox extends Component {

    state = {
        options: []
    }

    componentDidMount() {
        this.setState({ options: this.props.options });
    }

    handleChange = (index) => {
        const { options } = this.state;
        const { filterName, handleFilterPath } = this.props;
        let newFilterValue = []

        options[index].selected = !options[index].selected;

        this.setState({ options: options });

        options.map(item => {
            if (item.selected) {
                newFilterValue.push(parseInt(item.valor));
            }
        })

        handleFilterPath(filterName, newFilterValue.length > 0 ? newFilterValue : null);
    }

    render() {
        const { name, options } = this.props;

        return (
            <React.Fragment>
                {options && options.length > 0 &&
                    options.map((item, index) => {
                        return <FilterCheckBox key={`${name}-tag-${index}`}>
                            <FilterCheckBoxInput
                                vitrinecolor={Theme[vitrineType].main}
                                type="checkbox"
                                value={options[index].selected}
                                onClick={() => this.handleChange(index)}
                            />
                            {`${item.valor} ${item.descricao} (${
                                item.quantidade
                                } resultados)`}
                        </FilterCheckBox>
                    })
                }
            </React.Fragment>
        )

    }

}
import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { userLogout } from './../../actions';

import Hamburguer from '../hamburguer/hamburguer';
import SelectVitrine from '../select-vitrine/select-vitrine';
import Login from './../login/login';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

//Styled
import {
    HiddenControl,
    Divisor,
    AnchorContainer,
    TopbarContainer,
    TopbarWrapper,
    TopbarFilter,
    TopbarContent,
    TopbarWrapperIcon,
    Logo,
    LinkContainer,
    Overlay,
    TituloBtn,
    Subtitulo,
    SearchIcon,
    TopbarFilterBtn,
    SearchCloseIcon
} from './topbar-styled';

import { TextButton, Titulo } from '../commons/theme-styled';

// //Imgs
import background from './../../resources/imgs/capa-poly-bg.jpg';
import LogoClassi from '../../resources/svg/logos/logo-classi-agro.svg';

class Topbar extends React.Component {

    state = {
        textColor: '',
        openWrapper: false,
        openfilter: false,
        openOverlay: false
    };

    componentDidMount() {
        if (this.props.backgroundColor != '#FFF') {
            this.setState({ textColor: '#FFF' });
        }
    }

    createAccount = () => {
        if (window) {
            window.keycloak.register();
        }
    }

    toggleWrapper = e => {
        e.preventDefault();

        if (this.state.openOverlay) {
            this.closeWrapperFilterOverlay()
        } else {
            this.setState(prevState => ({
                openWrapper: !prevState.openWrapper,
                openOverlay: true
            }));
        }
    };

    toggleFilter = e => {
        e.preventDefault();

        if (this.state.openOverlay) {
            this.closeWrapperFilterOverlay()
        } else {
            this.setState(prevState => ({
                openfilter: !prevState.openfilter,
                openOverlay: true
            }));
        }
    };

    closeWrapperFilterOverlay = () => {
        this.setState({
            openWrapper: false,
            openfilter: false,
            openOverlay: false
        });
    }

    handleRedirectToHome = () => {
        const { location, history } = this.props;

        if (location.pathname !== '/' || window.location.origin !== window.location.href) {
            history.push('/')
        }
    }

    render() {
        const { busca, withAnchor, backgroundColor, filters, searchFilterData, handleFilter, presetFiltro, authentication, userLogout, location } = this.props;
        const { textColor, openWrapper, openOverlay, openfilter } = this.state;

        let Filters = filters;

        let hasKeys = authentication ? Object.keys(authentication) : null;
        hasKeys = hasKeys ? hasKeys.length > 0 : false;

        let isAuth = authentication ? authentication.isAuthenticated : false;
        let checkAuthenticated = hasKeys && !isAuth;
        let anchorContainer;

        if (withAnchor) {
            anchorContainer =
                <AnchorContainer >
                    <AnchorLink href="#vitrines" offset='100' className='first'>
                        <Button>
                            <TituloBtn>Ir para</TituloBtn>
                            <Subtitulo>Vitrines</Subtitulo>
                        </Button>
                    </AnchorLink>

                    <AnchorLink href="#anuncie" offset='50'>
                        <Button>
                            <TituloBtn>Criar</TituloBtn>
                            <Subtitulo>Anúncio</Subtitulo>
                        </Button>
                    </AnchorLink>

                    <AnchorLink href="#planos" offset='100' className='last'>
                        <Button>
                            <TituloBtn>Acesse seu</TituloBtn>
                            <Subtitulo>Plano</Subtitulo>
                        </Button>
                    </AnchorLink>
                </AnchorContainer>
        } else {
            anchorContainer =
                <AnchorContainer>
                    <TextButton btncolor={'#FFFFFF'} href={process.env.URL_ANUNCIE_FAVORITOS}>
                        Favoritos
                    </TextButton>

                    <TextButton href={process.env.URL_PLANOS} btncolor={'#FFFFFF'}>Acesse seu plano</TextButton>

                    {/* TODO: para onde vai a tela de cadastro? */}
                    {checkAuthenticated &&
                        <TextButton onClick={this.createAccount} btncolor={'#FFFFFF'}>Cadastre-se</TextButton>
                    }

                    <TextButton href={process.env.URL_ANUNCIE} btncolor={'#FFFFFF'} className={checkAuthenticated ? 'last' : ''}>Anuncie</TextButton>
                </AnchorContainer>
        }

        return (
            <div>
                <TopbarContainer backgroundColor={backgroundColor}>
                    <TopbarContent>
                        {!withAnchor &&
                            <Hamburguer onClick={this.toggleWrapper} open={openWrapper}></Hamburguer>
                        }

                        <LinkContainer to={{ pathname: '/' }}>
                            <Logo src={LogoClassi} />
                        </LinkContainer>

                        <HiddenControl desktop={false} mobile={true}>
                            {anchorContainer}
                            <Login
                                btncolor={'#FFFFFF'}
                                userLogout={userLogout}
                                routerLocation={location.pathname}
                                authentication={authentication} />
                        </HiddenControl>

                        <HiddenControl desktop={true} mobile={false}>
                            {busca &&
                                <TopbarWrapperIcon>
                                    {!openOverlay && <SearchIcon onClick={this.toggleFilter} />}
                                    {openOverlay && <SearchCloseIcon onClick={this.toggleFilter} />}
                                </TopbarWrapperIcon>
                            }

                            {withAnchor &&
                                <Login
                                    btncolor={'#FFFFFF'}
                                    userLogout={userLogout}
                                    routerLocation={location.pathname}
                                    authentication={authentication}
                                />
                            }
                        </HiddenControl>

                    </TopbarContent>

                    <TopbarWrapper bgimage={background} isopen={openWrapper}>
                        <HiddenControl desktop={true} mobile={false} display='block'>
                            <Login
                                btncolor="#9b43c4"
                                userLogout={userLogout}
                                routerLocation={location.pathname}
                                authentication={authentication}
                            />
                            <Divisor />
                        </HiddenControl>

                        <Titulo>O que você procura?</Titulo>
                        <SelectVitrine />
                    </TopbarWrapper>

                    <TopbarFilter isopen={openfilter} bgimage={background}>
                        {filters ?
                            <Filters
                                searchFilterData={searchFilterData}
                                handleFilter={handleFilter}
                                presetFiltro={presetFiltro}
                            />
                            : null
                        }

                        <TopbarFilterBtn btncolor={backgroundColor} onClick={this.toggleFilter}>
                            Aplicar
                        </TopbarFilterBtn>
                    </TopbarFilter>
                </TopbarContainer>

                <Overlay isopen={openOverlay} />
            </div>
        );

    }
}

Topbar.propTypes = {
    backgroundColor: PropTypes.string
}

Topbar.defaultProps = {
    withAnchor: false,
    backgroundColor: '#FFF'
}

const mapStateToProps = store => ({
    authentication: store.authentication.authentication
})

export default connect(mapStateToProps, { userLogout })(withRouter(Topbar));
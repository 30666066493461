import React from "react";
import { compose, withProps } from "recompose";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";
import { ContainerMapaAnuncio } from "./mapa-anuncio-styled";

const Mapa = compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${
            process.env.MAPA_API_KEY
            }&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100%`, width: `100%` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap
)(props => (
    <GoogleMap
        defaultClickableIcons={false}
        defaultZoom={13}
        defaultCenter={{ lat: props.lat, lng: props.long }}
        options={{
            gestureHandling: "greedy",
            fullscreenControl: false,
            streetViewControl: false,
            scaleControl: true,
            mapTypeControl: false,
            minZoom: 4
        }}
    >
        <Marker
            position={{
                lat: props.lat,
                lng: props.long
            }}
        />
    </GoogleMap>
));

export default class MapaAnuncio extends React.Component {
    state = {
        lat: undefined,
        long: undefined
    };

    componentDidUpdate(prevProps) {
        if (
            this.props.localizacao &&
            this.props.localizacao != prevProps.localizacao
        ) {
            let endereco;
            if (this.props.localizacao.cep)
                endereco = this.props.localizacao.cep.replace(/\D/g, "");
            else {
                endereco = this.cleanEndereco(this.props.localizacao);
            }
        }
    }

    cleanEndereco = localizacao => {
        let endereco = "";
        if (localizacao.bairro) {
            endereco = `${endereco}${localizacao.bairro
                .split(" ")
                .join("+")},+`;
        }
        if (localizacao.cidade) {
            endereco = `${endereco}${localizacao.cidade
                .split(" ")
                .join("+")},+`;
        }
        if (localizacao.numero) {
            endereco = `${endereco}${localizacao.numero
                .toString()
                .split(" ")
                .join("+")},+`;
        }
        if (localizacao.rua) {
            endereco = `${endereco}${localizacao.rua.split(" ").join("+")},+`;
        }
        if (localizacao.lote) {
            endereco = `${endereco}${localizacao.lote.split(" ").join("+")},+`;
        }
        if (localizacao.quadra) {
            endereco = `${endereco}${localizacao.quadra
                .split(" ")
                .toString()
                .join("+")},+`;
        }
        // remove os dois ultimos chars da string que normalmente vai ser ,+
        return endereco.slice(0, -2);
    };

    render() {
        const { lat, long } = this.state;
        return (
            <ContainerMapaAnuncio>
                {lat && <Mapa long={long} lat={lat} />}
            </ContainerMapaAnuncio>
        );
    }
}

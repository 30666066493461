import styled from 'styled-components';

export const FooterContainer = styled.div`
    width: 100%;
    padding-top: 15px;
    padding-left: 15%;
    padding-right: 15%;

    background-color: #2b043f;
    background-repeat: no-repeat;
    background-size: cover;
    Background-position: 0 100%;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;

    @media screen and (max-width: 700px){
        overflow: hidden;
    }
`;
export const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-itens: center;
    margin-top: 20px;

    @media screen and (max-width: 700px){
        width: 100%;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 0;
        margin-bottom: 15px;
    }
`;

export const SocialContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const UrlList = styled.nav`
    display: flex;
    flex-direction: column;
`;

export const InformacaoContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const UrlListItem = styled.p`
    font-size: 18px;
    font-weight: lighter;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #FFF;
`;

export const Logo = styled.img.attrs(props => ({
    width: '150',
    height: '87'
}))``;

export const Line = styled.div`
    width: 100%;
    border-top: 1px solid #ff9b29;
    margin-bottom: 20px;
`;

export const Titulo = styled.h2`
    font-size: 18px;
    color: ${ props => props.color ? props.color : '#FFF'};
    margin-bottom: 0;
`;

export const SimpleText = styled.p`
    width: 350px;
    font-size: 13px;
    color: #FFF;
    line-height: 1.85;
    font-weight: 300;
    margin-top: 10px;
`;

export const Icon = styled.img.attrs(props => ({
    width: '20',
    height: '20'
}))``;

export const Phone = styled.h2`
    font-size: 31px;
    color: ${ props => props.color ? props.color : '#FFF'};
    margin-top: 10px;
    margin-bottom: 0;
`;
import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import {
    Topbar,
    TopbarContainer,
    TopbarInfo,
    Titulo,
    Subtitulo,
    SubtituloMobile,
    Moeda,
    Valor,
    Heart,
    Codigo,
    CodigoMobile
} from "./topbar-interno-styled";
import Favoritar from "./../../components/favoritar/favoritar";

// Icons
import { Hidden } from "@material-ui/core";

// Styles

export default class TopBarInterno extends Component {
    render() {
        const {
            authentication,
            idAnuncio,
            isFavorite,
            titulo,
            subtitulo,
            valor,
            valorCombinar,
            codigo
        } = this.props;
        return (
            <Topbar>
                <TopbarContainer>
                    <TopbarInfo>
                        <Titulo>{titulo}</Titulo>

                        <Subtitulo>
                            {this.props.subtitulo ? this.props.subtitulo : ''}
                        </Subtitulo>
                    </TopbarInfo>

                    <Hidden smDown>
                        <TopbarInfo alignRight>
                            <Favoritar
                                isFavorite={isFavorite}
                                idAnuncio={idAnuncio}
                                authentication={authentication}
                                isAnuncioPage={true}
                                isTopAnunciePage={true}
                            />

                            <Subtitulo>Favoritar</Subtitulo>
                        </TopbarInfo>
                    </Hidden>

                    <TopbarInfo hideInfo={!valor}>
                        <Valor>
                            {valorCombinar ? (
                                "Valor a combinar"
                            ) : (
                                    <React.Fragment>
                                        R${" "}
                                        {valor &&
                                            valor.toLocaleString("pt-br", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}
                                    </React.Fragment>
                                )}
                        </Valor>
                        {codigo ? <Codigo>Cód. {codigo}</Codigo> : null}
                    </TopbarInfo>
                    <TopbarInfo hideInfo={false}>
                        <SubtituloMobile>
                            {this.props.subtitulo ? this.props.subtitulo : ''}
                        </SubtituloMobile>
                    </TopbarInfo>

                    <TopbarInfo hideInfo={!codigo}>
                        <CodigoMobile>Cód. {codigo}</CodigoMobile>
                    </TopbarInfo>
                </TopbarContainer>
            </Topbar>
        );
    }
}

import styled from 'styled-components';

// Icons
import Favorite from '@material-ui/icons/Favorite';

export const Topbar = styled.div`
    width: 100%;
    padding: 10px;
    padding-top: 20px;
    margin-top: 35px;

    background-color: #FFF;

    -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);

    @media screen and (max-width: 768px) {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
`;

export const TopbarContainer = styled.div`
    width: 100%;
    max-width: 1200px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

export const TopbarInfo = styled.div`
    display: ${props => props.hideInfo ? 'none' : 'flex'};
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    height: 72px;
    margin-left: ${props => props.alignRight ? 'auto' : null};
    margin-right: ${props => props.alignRight ? '60px' : null};

    @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 10px;

        height: auto;
        max-height: 72px;
        min-height: 2em;
    }
`;

export const Titulo = styled.h1`
    font-size: 31px;
    margin-top: 0;
    margin-bottom: 0px;
    font-weight: 400;

    max-width: 500px;
    height: 50px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;

    @media screen and (max-width: 768px) {
        width: 100%;
        height: auto;
        max-height: 90px;

        font-size: 21px;

        text-align: center;
        white-space: normal;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
`;

export const Subtitulo = styled.p`
    font-size: 18px;
    color: #626363;

    margin-top: 0;
    margin-bottom: 0;

    @media screen and (max-width: 768px){
        display: none;
    }
`;

export const SubtituloMobile = styled.p`
    font-size: 18px;
    color: #626363;

    margin-top: 0;
    margin-bottom: 0;

    display: none;

    @media screen and (max-width: 768px){
        text-align: center;
        margin-top: 8px;

        display: block;
    }
`;

export const Valor = styled.p`
    font-size: 41px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;

    height: 50px;
    position: relative;

    text-align: center;

    @media screen and (max-width: 768px) {
        font-size: 21px;

        height: auto;
        line-height: 1.5em;

        text-align: center;
        white-space: normal;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        white-space: nowrap; 
        margin-left: auto; 
        margin-right: 10px;


        &::before, &::after{
            content: '';
            position: absolute;
            width: calc(50% - 100px);
            border-top: 1px solid gray;
            margin-top: 14px;
        }

        &::before{
            right: 0;
        }

        &::after{
            left: 0;
        }
    }
`;

export const Codigo = styled.p`
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
    display: block;
    font-weight: 300;

    @media screen and (max-width: 768px) {
        margin-right: auto;
        display: none;
    }
`;

export const CodigoMobile = styled.p`
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
    display: none;
    font-weight: 300;

    @media screen and (max-width: 768px) {
        margin-right: auto;
        display: block;
    }
`;

export const Moeda = styled.span`
    font-size: 31px;
    margin-top: 0;
    margin-right: 10px;

    @media screen and (max-width: 768px) {
        font-size: 21px;
    }
`;
import React from "react";
import styled from 'styled-components';
import ImageRender from '../../components/image-render/image-render';

export const ImageGrid = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 20px 10px;

    justify-content: flex-start;

    @media screen and (max-width: 768px){
        padding: 0;
    }
`;

export const ImagePrincipal = styled(ImageRender)`
    width: 100%;
    max-height: 420px;
    object-fit: cover;

    cursor: pointer;

    @media screen and (max-width: 768px){
        max-height: 300px;
    }
`;

export const OtherImage = styled(ImageRender)`
    width: calc(25% - 4px);
    height: 150px;
    margin-top: 5px;
    object-fit: cover;
    cursor: pointer;

    margin-right: 5px;

    &:last-child{
        margin-right: 0;
    }

    @media screen and (max-width: 768px){
        height: 80px;
    }
`;
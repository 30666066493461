import React, { Component } from 'react';

//Components
import { Container } from './../../components/commons/theme-styled';

//Imgs
import LogoClassi from '../../resources/svg/logo-classi.svg';
import CardAnuncio from '../../components/card-anuncio/card-anuncio';
import Axios from 'axios';
import Loading from '../../components/loading/loading';

const anuncio = {
    id: 140352,
    categoriaDigital: {
        descricao: 'Indústria'
    },
    favorito: true,
    imagens: [
        { 
            imageUrl: "https://cdnqa.ojc.com.br/classificados/768/768e56df-e9ce-4657-b354-8c45512dfe52.png",
            ordem: 0,
            thumbnailUrl: "https://cdnqa.ojc.com.br/classificados/768/768e56df-e9ce-4657-b354-8c45512dfe52.png"
        }
    ],
    localizacao: {
        estado: 'GO',
        cidade: 'Itajá'
    },
    prioridade: 'MEDIA',
    secao: {
        descricao: 'imoveis',
        label: 'Imóveis',
        value: 'IMOVEIS'
    },
    titulo: 'Limpador de cabeçote',
    descricao: 'Descricao',
    valor: 1.99,
    url: '/imoveis/anuncio/limpador-de-cabecote-140352',
    turno: 'NOTURNO',
    escolaridadeMinima: { descricao: 'Fundamental' },
    tipoVaga: { descricao: 'Autônomo' },
    experienciaMinima: { descricao: '2 anos' }
}

class Desenvolvimento extends Component {

    render(){
        return  (
            <Container firstContainer={true} direction="column">
                <h1>Dev-loading</h1>
                <Loading />
            </Container>
        )
    }

}

export default {
    component: Desenvolvimento
}
import React, { Component } from 'react';

// Styles
import { CopyrightContainer, Text, CopyrightUrls, UrlItem } from './copyright-styled';
import { UrlList } from '../footer/footer-styled';

export default class Copyright extends Component{

    render(){

        return(
            <CopyrightContainer direction="row" backgroundColor="#f2f2f2">
                <Text>
                    © 2001-{(new Date().getFullYear())}. J. Câmara e Irmãos S/A. CNPJ 01.536.754/0001-23 - Rua Thomas Edson, 400, Serrinha - Goiânia - GO - 74853-130
                </Text>

                <CopyrightUrls>
                    <UrlItem href={process.env.URL_FAQ}>FAQ</UrlItem>
                    <UrlItem href={process.env.URL_FALECONOSCO}>FALE CONOSCO</UrlItem>
                    <UrlItem href={process.env.URL_TERMOS_DE_USO}>TERMOS DE USO</UrlItem>
                    <UrlItem href={process.env.URL_POLITICA_DE_PRIVACIDADE}>POLÍTICA DE PRIVACIDADE</UrlItem>
                </CopyrightUrls>
            </CopyrightContainer>
        );

    }

}
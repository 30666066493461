import styled from 'styled-components';

export const FavContainer = styled.div`
    ${props => !props.isAnuncioPage ?
    `
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    `
    :
    `
    width: 100%;
    height: 34px;
    `
    }

    &.top-anuncie {
        .fav-icon {
            position: absolute;
            right: 16px;
            top: 0;
        }
    }

    .fav-icon{
        ${props => !props.isAnuncioPage ? 
        `
        position: absolute;
        width: 28px;
        height: 25px;
        top: 10px;
        right: 7px;
        `
        : 
         `
        font-size: 44px;
        margin: auto;
         `
        }
        path:last-child{
            fill: ${ props => props.favorite ? '#e45353' : 'transparent' };
            stroke: ${ props => props.favorite == '#e45353' ? '#e45353' : 'gray' };
            cursor: pointer;

            &:hover{
                fill: #e45353;
                stroke: #e45353;
            }
        }
    }

    ${ props => !props.isAnuncioPage && `
        &:after{
            content: "";
            border-top: 65px solid #fff;
            border-left: 65px solid transparent;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }`
    }

`;
export const analyticsEvent = {
    BUSCAR: 'buscar',
    FILTRAR: 'filtrar',
    FAVORITAR: 'favoritar',
    DESFAVORITAR: 'desfavoritar',
    IMPRESSAO_CARD_ANUNCIO: 'impressao_do_card_anuncio',
    COMPARTILHAR_FB_TW_EMAIL: 'compartilhar_fb_tw_email',
    REPORTAR_ERRO: 'reportar_erro',
    CLICK_ANUNCIO: 'click_no_anuncio',
    CLICK_IMAGEM_ANUNCIO: 'click_imagem_anuncio',
    ENTRAR_CONTATO: 'entrar_em_contato',
    ENVIAR_MENSAGEM: 'enviar_mensagem',
    MENSAGEM_ENVIADA: 'mensagem_enviada',
    VER_TELEFONE: 'ver_telefone',
    VER_WHATSAPP: 'click_whatsapp',
    CLICK_TO_CALL: 'click_to_call'
}

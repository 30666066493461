import { StringUtils } from "./StringUtils";

/**
 * Suporte as URL's e suas variações de acordo com as as regras do Classi
 */
export const UrlsClassiUtils = {
    URL_ESTADO_REGEX: /\/\w{2}\-|\/\w{2}$/, //Match para "(...)/\w\w-(...)" ou "/\w\w$" que é a condição de UF na URL do Classi.
    URL_CIDADE_REGEX: /\/\w{2}\-[\w+\+]+\/{0,1}/, //Match para "(...)/{{UF}}-\w+[/(...)|$]" que é a condição de Cidade na URL do Classi.
    URL_NUMERO_QUARTOS_REGEX: /\d+\-quarto/,
    URL_SUBSECAO_REGEX: /busca\/[\w\+]+\//, //Match para o primeiro grupo de string após /\w\w-\w+/[MATCH], que é a condição para Subseção na URL.
    SEARCH_QUERY_REGEX: /query=.*&|query=.*$/,
    SEARCH_PAGINA_REGEX: /pagina=\d+/,

    TIPOS_DE_BUSCA: {
        IMOVEL_VENDA: "IMOVEL_VENDA",
        IMOVEL_ALUGUEL: "IMOVEL_ALUGUEL"
    },

    ALIAS_TIPOS_DE_BUSCA: {
        IMOVEL_VENDA: "comprar",
        IMOVEL_ALUGUEL: "alugar"
    },

    getEstado: function (url) {
        let estado = StringUtils.getPrimeiroMatch(url, this.URL_ESTADO_REGEX);

        return estado ? estado.replace(/\/|-/g, "") : estado;
    },
    getCidade: function (url) {
        let cidade = StringUtils.getPrimeiroMatch(url, this.URL_CIDADE_REGEX);

        if (cidade) {
            cidade = cidade.replace(/^\/\w{2}-/, "");
            cidade = cidade.replace("/", "");
        }

        return cidade;
    },
    getSubsecao: function (url) {
        let subsecao = url;

        if (subsecao) {
            subsecao = subsecao.replace(this.URL_SUBSECAO_REGEX, "");
        }

        return subsecao ? subsecao.replace(/\/|-/g, "") : categoria;
    },
    getQuery: function (search) {
        let query = StringUtils.getPrimeiroMatch(unescape(search), this.SEARCH_QUERY_REGEX);

        if (query) {
            query = query.replace("query=", "");
            query = query.replace("&", "");
        }

        return query;
    },
    getPagina: function (search) {
        let pagina = StringUtils.getPrimeiroMatch(
            search,
            this.SEARCH_PAGINA_REGEX
        );

        return pagina ? pagina.replace("pagina=", "") : pagina;
    },
    /**
     * Recebe o pathname, mais a UF, Cidade e a Categoria, retornando o nome pathname com os dados.
     * Lembrando que categoria somente é inserida no pathname se existir uf e cidade, e cidade só
     * e inserida se existir uf.
     *
     * Exemplo:
     *  pathname: busca/vagas/uf-go/domestica
     *  uf: DF
     *  cidade: Brasília
     *  categoria: Doméstica
     *
     *  retorno: 'busca/vagas/df-brasilia/domestica'
     *
     * @param {String} pathname
     * @param {String} uf
     * @param {String} cidade
     * @param {String} categoria
     */
    makeNewPathname: function (pathname, uf, cidade, subsecao) {
        let newPathname = "";

        if (pathname) {
            let pathnameBase = StringUtils.getPrimeiroMatch(pathname, /\w+\//);

            if (pathnameBase && pathnameBase.length) {
                pathnameBase = pathnameBase.replace("/", "");

                newPathname =
                    pathnameBase +
                    "/" +
                    this.constructFiltrosPathname(uf, cidade, subsecao);
            }
        }

        return newPathname;
    },
    /**
     * Cria a string search, a partir dos parametros de query e pagina passado como valores.
     *
     * Exemplo:
     *  searchString: 'pagina=1&query=cola'
     *  query: 'Ferro de Passar'
     *  pagina: '2'
     *
     *  retorno 'pagina=2&query=Ferro de Passar'
     *
     * @param {String} searchString
     * @param {String} query
     * @param {String} pagina
     * @param {Boolean} stayInPage Caso verdadeiro utiliza a página (se existir) da searchString, caso contrário utiliza o valor de 'pagina' e em último caso '1'.
     */
    makeNewSearch: function (searchString, query, pagina, stayInPage) {
        let search = "";

        if (searchString) {
            let queryString = "";

            if (query) {
                queryString = "query=" + query;
            }

            let page = "pagina=" + (pagina || pagina === 0 ? pagina : "0");

            if (stayInPage) {
                let pagePos = this.getPagina(searchString);

                if (pagePos) {
                    page = "pagina=" + pagePos;
                }
            }

            search = page + (queryString ? "&" + queryString : "");
        }

        return search;
    },
    /**
     * Monta o pathname de busca de acordo com o tipo de busca informado.
     * Para conveniência o tipoDeBusca pode ser os valores setados no UrlsClassiUtils.TIPO_DE_BUSCA
     *
     * Exemplo:
     *  tipoDeBusca: 'vagas'
     *
     *  retorno 'busca/vagas/'
     *
     * @param {String} tipoDeBusca
     */
    makeBuscaPathnamePorTipoBusca: function (tipoDeBusca) {
        let newPathname = "";

        if (tipoDeBusca) {
            newPathname = "/busca/" + tipoDeBusca + "/";
        }

        return newPathname;
    },
    /**
     * Constrói os filtro para pathname com base nos parâmetros passados.
     *
     * Exemplo:
     *  uf: GO
     *  cidade: Goiânia
     *  bairro: Novo Mundo
     *  categoria: undefined
     *
     *  retorno 'df-brasilia/'
     *
     * @param {String} uf
     * @param {String} cidade
     * @param {String} bairro
     * @param {String} categoria
     * @param {String} numeroQuartos
     */
    constructFiltrosPathname: function (estado, cidade, categoria) {
        let filtroPathname = "";

        if (estado && cidade) {
            filtroPathname += `${StringUtils.cleanString(estado)}-${StringUtils.cleanString(cidade)}`;
        } else {
            filtroPathname += 'brasil';
        }

        if (categoria) {
            filtroPathname += `/${StringUtils.cleanString(categoria).toLowerCase()}`;
        }

        return filtroPathname;
    }
};

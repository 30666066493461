import React from 'react';
import { renderRoutes } from 'react-router-config';

// Components
import Footer from './components/footer/footer';
import Copyright from './components/copyright/copyright';

// Colors
import { Theme } from './components/commons/vitrine-colors';

const App = ({ route }) => {
  return (
    <div>

      {renderRoutes(route.routes)}

      <Footer />
      <Copyright />
    </div>
  );
};

export default {
  component: App
};

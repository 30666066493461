// TODO: Colocar secao para pegar do .env

export const mockupFiltro = {
    categoria: null,
    cidade: null,
    estado: null,
    ordenacao: "RELEVANCIA",
    pagina: 1,
    paginado: true,
    qtdRegistros: 6
};

export const searchFilterList = {
    aceitaFinanciamento: {
        name: "aceitaFinanciamentoFilter",
        label: "Aceita financiamento?",
        filter: "aceitaFinanciamento"
    },
    aceitaTroca: {
        name: "aceitaTrocaFilter",
        label: "Aceita troca?",
        filterName: "aceitaTroca"
    },
    city: {
        name: "cityFilter",
        label: "Cidade:",
        placeholder: "Selecione a cidade",
        filterName: "cidade"
    },
    especieModelo: {
        name: "especieModeloFilter",
        label: "Espécie/Modelo:",
        placeholder: "Selecione",
        filterName: "especieModelo"
    },
    query: {
        name: "query",
        label: "O que você procura?",
        placeholder: "Digite sua busca",
        filterName: "query"
    },
    state: {
        name: "stateFilter",
        label: "Estado:",
        placeholder: "Selecione o estado",
        filterName: "estado"
    },
    subsecao: {
        name: "subsecaoFilter",
        label: "Subseção:",
        placeholder: "Escolha",
        filterName: "categoria"
    },
    tipoMarca: {
        name: "tipoMarcaFilter",
        label: "Categoria/Tipo/Marca:",
        placeholder: "Escolha",
        filterName: "tipoMarca"
    },
    tipoNegociacao: {
        name: "tipoNegociacaoFilter",
        label: "Tipo de negociação:",
        placeholder: "Selecione",
        filterName: "tipoNegociacao"
    },
    vlrMinimo: {
        name: "valor-minimo",
        placeholder: "Minimo",
        filterName: "vlrMinimo"
    },
    vlrMaximo: {
        name: "valor-maximo",
        placeholder: "Máximo",
        filterName: "vlrMaximo"
    }
};

import React, { Component } from 'react';

// Styles
import {
    Container,
    Titulo,
    PostTitulo,
    BlogButton,
    FeedContainer,
    Post,
    LastPost,
    Image,
    Descricao,
    SimpleText,
    LinkBtn
} from './blog-feed-styled';
import Axios from 'axios';

export default class BlogFeed extends Component {
    constructor(props) {
        super(props);

        this.state = { feed: null }
    }

    componentDidMount() {
        Axios.get(`${process.env.API_URL}/blog-classi/posts`)
            .then(response => {
                this.setState({ feed: response.data })
            });
    }

    render() {
        const { feed } = this.state;
        return (
            <React.Fragment>
                {feed &&
                    <Container>
                        <Titulo>
                            Hoje no Blog.Classi.com.br:
                        </Titulo>

                        <FeedContainer direction="column">
                            <LastPost lastPost>
                                <Image src={feed[0].urlImage} />

                                <Descricao>
                                    <PostTitulo>{feed[0].title}</PostTitulo>
                                    <SimpleText>
                                        {feed[0].description}
                                    </SimpleText>
                                    <LinkBtn href={feed[0].link} target="_blank">
                                        Leia mais
                                        </LinkBtn>
                                </Descricao>
                            </LastPost>
                        </FeedContainer>

                        <FeedContainer direction="row">
                            {feed.length > 1 &&
                                feed.map((item, index) => {
                                    // Ignora o primeiro item
                                    if (index == 0) return

                                    return <Post key={`post-${index}`}>
                                        <Image src={item.urlImage} />

                                        <Descricao>
                                            <PostTitulo>{item.title}</PostTitulo>
                                            <SimpleText>
                                                {item.description}
                                            </SimpleText>
                                            <LinkBtn href={item.link} target="_blank">
                                                Leia mais
                                            </LinkBtn>
                                        </Descricao>
                                    </Post>
                                })
                            }
                        </FeedContainer>

                        <BlogButton href="https://blog.classi.com.br/" target="_blank">
                            Veja mais no Blog do Classi.com
                        </BlogButton>
                    </Container>
                }
            </React.Fragment>
        )

    }

}
import { FETCH_ANUNCIOS_CLUSTER } from "../actions/types";

const INITIAL_STATE = {
    searchCluster: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_ANUNCIOS_CLUSTER:
            return { ...state, searchCluster: action.payload.data };
        default:
            return state;
    }
};

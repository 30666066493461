import { 
  FETCH_CURRENT_USER,
  DELIVER_DEFAULT_USER,
  FETCH_USER_INFO
} from '../actions/types';

const INITIAL_VALUE = {
  authentication: {
    isAuthenticated: false,
    userEmail: '',
    userName: '',
    userInfo: null
  },
}

export default function(state = INITIAL_VALUE, action) {
  switch (action.type) {
    case FETCH_CURRENT_USER:
      return { ...state, authentication: action.payload }
    case DELIVER_DEFAULT_USER:
      return { ...state, authentication: INITIAL_VALUE.authentication }
    case FETCH_USER_INFO:
      state.authentication['userInfo'] = action.payload;
      return state;
    default:
      return state;
  }
}

import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 20px;

    @media screen and (max-width: 768px){
        margin-top: 20px;
    }
`;

export const Titulo = styled.h4`
    width: 100%;
    max-width: 840px;

    font-weight: bold;
    font-size: 31px;
    color: #9b44a9;

    margin-top: 10px;
    margin-bottom: 0;

    @media screen and (max-width: 768px){
        text-align: center;
    }
`;

export const PostTitulo = styled.h4`
    width: 100%;
    height: 110px;
    max-width: 815px;
    
    font-weight: bold;
    font-size: 31px;
    color: #9b44a9;
    text-align: left;

    margin-top: 10px;
    margin-bottom: 0;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`;

export const FeedContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: ${props => props.direction ? props.direction : "initial"};
    align-items: center;
    justify-content: center;

    margin-top: 10px;
    margin-bottom: 40px;

    @media screen and (max-width: 768px){
        flex-direction: column;
    }
`;

export const LastPost = styled.div`
    width: 100%;
    background-color: #defcff;
    padding: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;

    @media screen and (max-width: 768px){
        flex-direction: column;
    }
`;

export const Post = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px){
        margin-bottom: 20px;
    }
`;

export const BlogButton = styled(Button)`
    font-size: 25px;
    font-weight: bold;
    color: #FFF;
    background-color: #9b44a9;
    border-radius: 2px;
    padding: 10px 50px;

    @media screen and (max-width: 768px){
        font-size: 18px;
        text-align: center;
        
        margin-bottom: 20px;
    }
`;

export const Image = styled.img`
    width: ${props => props.LastPost ? '350px' : '400px'};
    height: 250px;
    object-fit: cover;

    @media screen and (max-width: 768px){
        width: 100%;
    }
`;

export const Descricao = styled.div`
    max-width: 400px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
`;

export const SimpleText = styled.p`
    max-height: 120px;
    color: #000000;
    margin-top: 10px;

    line-height: 1.5;

    text-align: left;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
`;

export const LinkBtn = styled.a`
    font-weight: bold;
    color: #000000;
    margin-top: 20px;

    &:hover{
        text-decoration: underline !important;
    }
`;
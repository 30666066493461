import { UPDATE_GOOGLE_MAPS } from "../actions/types";

const INITIAL_VALUE = {
    mostrarMapaDesktop: true,
    mostrarMapaMobile: false,
    carrosselIndex: 0,
    mostrarCarroseel: false
};

export default function(state = INITIAL_VALUE, action) {
    switch (action.type) {
        case UPDATE_GOOGLE_MAPS:
            return {
                ...state,
                mostrarMapaDesktop: action.payload.mostrarMapaDesktop,
                mostrarMapaMobile: action.payload.mostrarMapaMobile
            };
        default:
            return state;
    }
}

export const anuncioMockup = (parameter) => {
    const anuncioId = parameter.split('-').pop();

    return anuncioId;
}

export const anuncioEventoAnalytics = (anuncio) => {

    const obj = {};

    if (anuncio) {

        if (anuncio.id) {
            obj.idAnuncio = anuncio.id
        }

        if (anuncio.anunciante && anuncio.anunciante.idContrato) {
            obj.idContrato = anuncio.anunciante.idContrato;
        }

    }

    return obj;

}

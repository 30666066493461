import React, { Component } from 'react';
import { OrdenacaoContainer, OrdenacaoLabel, OrdenacaoList } from './ordenacao-styled';

export default class Ordenacao extends Component{

    state = {
        sortValue: 'RELEVANCIA'
    }

    handleFilterPath = (e) =>{ 
        const { filterName, handleFilter } = this.props;

        handleFilter( filterName, e.target.value ) ;
    }

    render(){
        const { sortList } = this.props;

        return(
            <OrdenacaoContainer>
                <OrdenacaoLabel>Ordenar por:</OrdenacaoLabel>
                <OrdenacaoList vitrinecolor={ this.props.vitrinecolor } onChange={ this.handleFilterPath }>
                    {sortList ? sortList.map(item => {
                        return <option key={`key-${item.valor}`} value={item.valor} >{item.descricao}</option>
                    }) : null}
                </OrdenacaoList>
            </OrdenacaoContainer>
        );

    }

}
import React, { Component } from 'react';
import { Container, TagContainer, TagItem, SelectInput, RemoveTag } from './select-tag-styled';
import { Theme } from './../commons/vitrine-colors';

const vitrineType = process.env.VITRINE_TYPE.toLowerCase();

export default class SelectTag extends Component {

    state = {
        stateOptions: this.props.options,
        tagList: [],
        inputValue: {},
        filterValue: null
    }

    componentDidUpdate(prevProps, prevState) {
        const { filterValue } = this.state;

        if (!filterValue && this.props.defaultValue) {
            this.props.defaultValue.map(item => {
                this.handleRoleElements(item, false);
            })
        }

        if (prevProps != this.props) {
            this.setState({ stateOptions: this.props.options });
        }
    }

    // TODO: update tagList from localStorage
    handleRoleElements = (tagData, removeThis) => {
        const { tagList, stateOptions } = this.state;
        const { handleFilterPath, filterName } = this.props;

        let newTagList = tagList ? tagList : [],
            newOptions = stateOptions ? stateOptions : [];

        if (removeThis) {
            newTagList = changeElementList(newTagList, newOptions);
        } else {
            newOptions = changeElementList(newOptions, newTagList);
        }

        function changeElementList(toRemoval, toAdd) {
            if (toRemoval) {
                return toRemoval.filter(element => {
                    if (element.descricao !== tagData.descricao) {
                        return element
                    }
                    toAdd.push(element);
                    return;
                });
            }
        }

        this.setState({
            tagList: newTagList,
            stateOptions: newOptions ? newOptions.sort((a, b) => a.valor - b.valor) : [],
            filterValue: stateOptions
        })

        if (Object.keys(tagData).length > 0 && tagData.valor) {
            let arrayValues = [];

            for (let i of newTagList) {
                arrayValues.push(i.valor);
            }

            handleFilterPath(filterName, (arrayValues.length > 0 ? arrayValues : null));
        }

    }

    resetFilter = () => {
        this.setState({ tagList: [] });
    };

    render() {
        const { tagList, stateOptions } = this.state;
        const { isDisabled } = this.props;

        return (
            <Container>
                <SelectInput
                    options={stateOptions}
                    value={null}
                    placeholder={this.props.placeholder}
                    onChange={e => this.handleRoleElements(e, false)}
                    onFocus={e => this.handleRoleElements(e, false)}
                    getOptionLabel={option => option.descricao}
                    getOptionValue={option => option.valor}
                    noOptionsMessage={() => "Nenhum resultado encontrado"}
                    isDisabled={isDisabled}
                    vitrinecolor={Theme[vitrineType].main}
                />

                <TagContainer>
                    {tagList.map(tag => {
                        return (
                            <TagItem key={tag.valor}>
                                <span>{tag.descricao}</span>
                                <RemoveTag onClick={() => this.handleRoleElements(tag, true)} />
                            </TagItem>
                        )
                    })}
                </TagContainer>
            </Container>
        );
    }
}
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { ModalContainer, ModalPaper, ModalTitle, ModalSubTitle, ModalContent, ModalClose } from './modal-styled';

// Icons
import CloseIcon from '@material-ui/icons/Close';

const Modal = ({ titulo, subtitulo, children }, ref) => {

    const [openModal, setOpenModal] = useState(false);

    useImperativeHandle(ref, () => ({

        toggleModal() {
            setOpenModal(!openModal)
        }

    }))

    return (
        <ModalContainer
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={openModal} >

            <ModalPaper>

                <ModalClose aria-label="fechar" onClick={() => setOpenModal(false)}>
                    <CloseIcon />
                </ModalClose>

                {titulo && <ModalTitle>{titulo}</ModalTitle>}
                {subtitulo && <ModalSubTitle>{subtitulo}</ModalSubTitle>}

                <ModalContent>
                    {children}
                </ModalContent>

            </ModalPaper>

        </ModalContainer>

    )

}

export default forwardRef(Modal);
import React, { Component } from 'react';

import IconButton from '@material-ui/core/IconButton';
import { PaginacaoContainer, Resultados, PaginasList, PrevBtn, NextBtn, Item, IconContainer } from './paginacao-styled';

export default class Paginacao extends Component {
    constructor(props) {
        super(props);

        this.state = {
            paginas: 2,
            resultados: 100,
            limiteItems: 5,
            midPage: 3
        }

        this.createList = this.createList.bind(this);
    }

    componentDidMount() {
        this.createList();
    }

    nextPage() {
        const { paginaAtual } = this.props;

        this.handleFilterPath({
            valor: paginaAtual + 1
        });
    };

    prevPage() {
        const { paginaAtual } = this.props;

        this.handleFilterPath({
            valor: paginaAtual - 1
        });
    };

    handleFilterPath = (selected) => {
        const { filterName, handleFilter } = this.props;
        handleFilter(filterName, selected.valor);
    }

    createList() {
        let array = [], startPage, endPage;
        const { limiteItems, midPage } = this.state;
        const { paginaAtual, qntdPaginas } = this.props;

        if (qntdPaginas <= limiteItems) {
            startPage = 0;
            endPage = qntdPaginas;
        } else {
            if (paginaAtual <= Math.round(limiteItems / 2)) {
                startPage = 0;
                endPage = limiteItems;
            } else if (paginaAtual - 3 <= 1) {
                startPage = 0;
                endPage = limiteItems;
            } else if (paginaAtual + 3 >= qntdPaginas) {
                startPage = qntdPaginas - (limiteItems - 1);
                endPage = qntdPaginas;
            } else {
                startPage = paginaAtual - 2;
                endPage = paginaAtual + 2;
            }
        }

        for (let i = startPage; i < endPage; i++) {
            array.push({ label: i + 1, active: i === paginaAtual, valor: i });
        }
        return array;
    }

    render() {
        const { qtdTotalRegistros, filterName, qntdPaginas, paginaAtual } = this.props;

        return (
            <PaginacaoContainer vitrinecolor={this.props.vitrinecolor}>
                {qtdTotalRegistros == 1 && <Resultados> {qtdTotalRegistros} resultado</Resultados>}
                {qtdTotalRegistros > 1 && <Resultados> {qtdTotalRegistros} resultados</Resultados>}

                <PaginasList>
                    {paginaAtual > 0 &&
                        <IconContainer onClick={() => this.prevPage()}>
                            <PrevBtn />
                        </IconContainer>
                    }

                    {qntdPaginas > 0 &&
                        this.createList().map(item => {
                            return <Item
                                active={item.active.toString()}
                                key={item.label}
                                value={item.valor}
                                onClick={() => this.handleFilterPath(item)}
                            >{item.label}
                            </Item>
                        })
                    }

                    {paginaAtual < qntdPaginas - 1 &&
                        <IconContainer onClick={() => this.nextPage()}>
                            <NextBtn />
                        </IconContainer>
                    }
                </PaginasList>

            </PaginacaoContainer>
        );

    }

};
import { STORE_USER_FILES } from '../actions/types';

const INITIAL_VALUES = {
  storedUserFiles: []
}

export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case STORE_USER_FILES:
      return { ...state, storedUserFiles: action.payload }
    default:
      return state;
  }
};

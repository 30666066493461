import styled from "styled-components";

import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { darken, lighten } from "@material-ui/core/styles/colorManipulator";
import Select from "react-select";
import AsyncSelect from 'react-select/lib/Async';
import TextField from "@material-ui/core/TextField";

import Clear from "@material-ui/icons/Clear";

export const FilterContainer = styled.form`
    display: flex;
    flex-direction: column;

    margin-left: ${props => (props.busca ? "0" : "10%")};
    margin-right: ${props => (props.busca ? "20px" : "0")};

    @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
    }
`;

export const FilterAutocomplete = styled(AsyncSelect).attrs({
    classNamePrefix: "autocomplete"
})`
    &&{
        width: 100%;
    }
    .autocomplete_{
        &_control {
            border: ${props => props.vitrinecolor ? "2px solid " + props.vitrinecolor : null};
            border-radius: 0;

            &:hover {
                background-color: transparent;
                border: ${props => props.vitrinecolor
        ? "2px solid " + props.vitrinecolor
        : "2px solid #FFF"};
                border-radius: 0;
                box-shadow: none;
            }
        }
        &_placeholder,
        &_indicator svg,
        &_menu,
        &_single-value,
        &_input {
            color: ${props => (props.vitrinecolor ? props.vitrinecolor : "#FFF")};
            font-size: 16px;
            font-style: italic;
        }
    }
`;

export const FilterDualItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & > input {
        width: calc(50% - 10px) !important;
        max-width: 140px;
    }

    span {
        font-size: 18px;
        margin-left: 5px;
        margin-right: 5px;
    }
`;

export const Filter = styled.div`
    ${props => props.busca && "max-width: 310px"};
    min-width: 300px;
    display: flex;
    flex-direction: ${props => props.direction};
    align-items: center;
    justify-content: flex-end;

    padding: 10px;
    margin-bottom: 10px;

    background-color: ${props => (props.busca ? "#FFF" : null)};
    border-top: ${props =>
        props.noBorder ? null : "3px solid " + props.vitrinecolor};
    box-shadow: ${props => (props.noBorder ? null : "-1px 4px 5px 0 #9b9b9b")};

    @media screen and (max-width: 768px) {
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin: auto;
        margin-bottom: 5px;
    }
`;

export const FilterCodigo = styled(TextField)`
    && {
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;

        input,
        label {
            font-size: 18px;
            color: #fff;
        }

        div,
        div:hover {
            &:before,
            &:after {
                border-bottom-color: #fff !important;
            }
        }

        div:hover:not(div):not(div):not(div):before {
            border-bottom-color: #fff !important;
        }
    }
`;

export const FilterLabel = styled.p`
    width: ${props => (props.busca ? "100%" : "150px")};
    font-size: ${props => (props.busca ? "18px" : "21px")};
    font-weight: 600;
    text-align: ${props => (props.busca ? "left" : "right")};
    color: ${props => (props.vitrinecolor ? props.vitrinecolor : "#FFF")};
    margin-top: ${props => (props.busca ? "10px" : "0")};
    margin-bottom: ${props => (props.busca ? "5px" : "0")};
    margin-right: ${props => (props.busca ? "0" : "20px")};
    white-space: nowrap;

    @media screen and (max-width: 768px) {
        text-align: center;

        &:nth-child(1) {
            margin-bottom: 5px;
            margin-right: 0;
        }
    }

    &:nth-child(1) {
        margin-top: 0;
    }
`;

export const FilterInput = styled.input`
    && {
        width: ${props => (props.busca ? "100%" : "300px")};
        height: 40px;
        padding: 10px;
        color: ${props => (props.vitrinecolor ? "gray" : "#FFF")};
        font-size: 16px;
        border: ${props =>
        props.vitrinecolor
            ? "2px solid " + props.vitrinecolor
            : "2px solid #FFF"};
        background-color: transparent;

        ::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            font-weight: normal;
            font-style: italic;
            color: ${props => (props.vitrinecolor ? "gray" : "#FFF")};
        }

        @media screen and (max-width: 768px) {
            width: 100%;
            margin-top: 5px;
        }
    }
`;

export const FilterSelect = styled(Select).attrs({
    classNamePrefix: "select"
})`
    && {
        width: ${props => (props.busca ? "100%" : "calc(100% - 150px)")};
        min-width: ${props => (props.busca ? "initial" : "150px")};
        ${props => props.defaultSearchSize && "min-width: 300px"};

        @media screen and (max-width: 768px) {
            width: 100%;
            margin-bottom: 10px;
        }
    }
    .select_ {
        &_control {
            background-color: transparent;
            border: ${props =>
        props.busca
            ? "2px solid " + props.vitrinecolor
            : "2px solid #FFF"};
            border-radius: 0;
            box-shadow: none;

            &--is-disabled {
                opacity: 0.5;
            }

            &:hover {
                background-color: transparent;
                border: ${props =>
        props.busca
            ? "2px solid " + props.vitrinecolor
            : "2px solid #FFF"};
                border-radius: 0;
                box-shadow: none;
            }
        }

        &_single-value {
            width: 100%;
        }

        &_placeholder,
        &_indicator svg,
        &_menu,
        &_single-value,
        &_input {
            color: ${props => (props.busca ? props.vitrinecolor : "#FFF")};
            font-size: 16px;
            font-style: italic;
        }

        &_indicator-separator {
            background-color: ${props =>
        props.busca ? props.vitrinecolor : "#FFF"};
        }

        &_option {
            color: ${props => props.vitrinecolor};
        }

        &_option:hover,
        &_option--is-focused {
            background-color: ${props => lighten(props.vitrinecolor, 0.8)};
            cursor: pointer;
        }

        &_option--is-selected {
            background-color: #fff;
        }

        &_menu {
            border-radius: 0;
            margin-top: 0;
        }
    }
`;

export const FilterSelectLocal = styled(FilterSelect)`
    && {
        width: calc(50% - 75px);

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }
`;

export const FilterApplyBtn = styled(Button)`
    && {
        width: 100%;
        height: 40px;

        color: ${props => (props.vitrinecolor ? props.vitrinecolor : "#000")};
        background-color: #fff;
        border: 2px solid
            ${props => (props.vitrinecolor ? props.vitrinecolor : "#000")};
        border-radius: 0;

        margin-top: 10px;

        &:hover {
            color: #fff;
            background-color: ${props =>
        props.vitrinecolor ? props.vitrinecolor : "#000"};
        }
    }
`;

export const SelectButtonContainer = styled.div`
    width: ${props => (props.busca ? "100%" : "calc(100% - 150px)")};
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 10px;
    }
`;

export const SelectButton = styled(Button)`
    && {
        width: 50%;
        border: ${props =>
        props.bordercolor
            ? "2px solid " + props.bordercolor
            : "2px solid #FFF"};
        border-radius: 0;

        font-weight: ${props => (props.busca ? "normal" : "bold")};
        color: #fff;
        background-color: ${props =>
        props.selected ? "#288394" : props.vitrinecolor};

        span {
            font-size: ${props => (props.busca ? "15px" : null)};
        }

        &:first-child {
            ${props => props.busca && "margin-right: 10px;"}
        }

        &:hover {
            background: ${props =>
        props.busca
            ? darken(props.selected ? props.vitrinecolor : "#FFF", 0.1)
            : darken(
                props.selected ? "#288394" : props.vitrinecolor,
                0.1
            )};
        }

        @media screen and (max-width: 768px) {
            width: 100%;
        }

        ${props =>
        props.busca
            ? `
            background-color: ${props.selected ? props.vitrinecolor : "#FFF"}; 
            color: ${props.selected ? "#FFF" : props.vitrinecolor};
            height: 30px;
            padding: 0 5px;
            `
            : null}
    }
`;

export const FilterCheckBox = styled.label`
    width: 100%;
    display: flex;
    flex-direction: row;
    color: #4a4a4a;
    cursor: pointer;
`;

export const FilterCheckBoxInput = styled.input`
    width: 20px;
    height: 20px;
    margin: 0;
    position: relative;
    cursor: pointer;
    border: 2px solid ${props => props.vitrinecolor};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    outline-style: none;
    margin-right: 5px;
    margin-bottom: 5px;

    &::after,
    &::before {
        content: "";
    }

    &::after {
        width: 20px;
        height: 20px;
    }

    &:checked::before {
        width: 4px;
        height: 8px;
        position: absolute;
        top: 2px;
        left: 5px;
        display: table;
        border: 3px solid ${props => props.vitrinecolor};
        border-top-width: 0;
        border-left-width: 0;
        transform: rotate(45deg);
    }
`;

export const FilterTagContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
`;

export const FilterTagItem = styled.div`
    color: #fff;
    background-color: #9e9e9e;
    padding: 3px 5px;

    display: flex;
    flex-direction: row;
    align-items: center;

    margin: 2px;

    span {
        font-size: 14px;
        font-weight: bold;
    }
`;

export const ClearIcon = styled(Clear)`
    && {
        font-size: 18px;
        cursor: pointer;
    }
`;

export const BuscaBtn = styled(Link)`
    && {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        width: 170px;
        height: 50px;
        font-size: 21px;
        font-weight: 600;
        background-color: ${props =>
        props.buscacolor ? props.buscacolor : "#FFF"};
        color: ${props => (props.buscacolor ? "#FFF" : null)};
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        margin-top: 20px;
        margin-left: auto;

        &:hover {
            background: ${props =>
        darken(props.buscacolor ? props.buscacolor : "#FFF", 0.1)};
        }

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }
`;
import React from "react";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { darken } from "@material-ui/core/styles/colorManipulator";
import ImageRender from "../../components/image-render/image-render";

import defaultImg from "./../../resources/svg/logos/logo-classi-agro.svg";
import bgImage from "./../../resources/svg/bg-vitrines/fundo-agro.png";

export const CardContainer = styled(Paper)`
    && {
        height: 250px;

        width: 100%;
        max-width: 1024px;
        min-width: 300px;

        display: flex;
        flex-direction: row;

        position: relative;

        border-radius: 0;
        border-top: 3px solid ${props => props.vitrinecolor};
        margin-bottom: 20px;

        @media screen and (max-width: 768px) {
            width: calc(100% - 20px);
            height: 150px;

            margin-left: auto;
            margin-right: auto;
        }

        &.media,
        &.alta {
        }

        &.alta {
            border-top: 0;
        }
    }
`;

export const CardMarcaContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    grid-column-start: 2;
    grid-row-start: 2;
    grid-row-end: 4;

    padding-right: 10px;

    min-width: 100px;

    p {
        margin-bottom: 5px;
    }

    @media screen and (max-width: 768px) {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 3;
        grid-column-end: 3;
        align-items: flex-end;
        justify-content: center;
        min-width: auto;

        p {
            font-size: 14px;
            margin-top: 0;
            margin-bottom: 5px;
        }
    }

    @supports (-webkit-overflow-scrolling: touch) {
        margin-bottom: 8px;
    }
`;

export const CardMarca = styled.img`
    width: 75px;
    height: 50px;

    object-fit: contain;

    @media screen and (max-width: 768px) {
        width: 50px;
        height: 30px;
    }
`;

export const CardImageContainer = styled.div`
    width: 280px;

    height: 100%;
    max-height: 350px;

    @media screen and (max-width: 768px) {
        width: 140px;
    }
`;

export const CardImage = styled(props => (
    <Link to={{ pathname: props.url }} {...props}>
        <ImageRender src={props.src} width="300" height="300" className="image" />    
    </Link>
))`
    && {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            background: url(${defaultImg}), url(${bgImage});
            background-position: 50%, 57%;
            background-repeat: no-repeat;
            background-size: 90%, auto 100%;
        }
    }
`;

export const CardInfoContainer = styled.div`
    width: calc(100% - 200px);
    height: 100%;
    position: relative;

    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        width: calc(100% - 140px);

        display: flex;
        flex-direction: column;
    }
`;

export const CardPrioridade = styled.div`
    width: 100%;
    height: 25px;
    padding: 2px 10px;
    text-transform: uppercase;

    &.media {
        color: ${props => props.vitrinecolor};
        background-color: #fff;
    }

    &.alta {
        color: #fff;
        background-color: ${props => props.vitrinecolor};
    }
`;

export const CardInfo = styled.div`
    width: 100%;
    height: calc(100% - 25px);

    padding: 10px;

    @media screen and (max-width: 768px) {
        max-height: 370px;
    }

    @supports (-webkit-overflow-scrolling: touch) {
        display: block;
        max-height: unset;
        height: auto;
    }
`;

export const CardTituloContainer = styled.div`
    @media screen and (max-width: 768px) {
        width: 100%;
    }

    @supports (-webkit-overflow-scrolling: touch) {
        float: left;
        max-width: 60%;
    }
`;

export const CardTitulo = styled.h2`
    font-size: 31px;
    font-weight: normal;

    margin-top: 0;
    margin-bottom: 0;

    text-transform: capitalize;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 410px;

    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
`;

export const CardSubtitulo = styled.p`
    font-size: 18px;
    color: #626363;

    margin-top: 0;
    margin-bottom: 0;

    @media screen and (max-width: 768px) {
        font-size: 16px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
    }
`;

export const CardItem = styled.div`
    text-align: center;
    width: 25%;

    @media screen and (max-width: 768px) {
        width: 20%;
    }

    @media screen and (max-width: 1336px) {
        margin-bottom: 8px;
        max-width: 85px;
    }
`;

export const CardPreco = styled.p`
    font-size: 24px;
    text-align: right;
    z-index: 1;

    position: absolute;
    top: 40px;
    right: 10px;

    margin-top: 0;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 1336px) {
        word-break: break-word;
    }

    @media screen and (max-width: 768px) {
        font-size: 18px;

        top: inherit;
        bottom: 0;

        margin-top: 5px;
        margin-bottom: 10px;
        margin-right: 0;

        white-space: nowrap; 
        margin-left: auto;

        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
    }

    @supports (-webkit-overflow-scrolling: touch) {
        float: right;
        max-width: 40%;
        word-break: break-word;
    }
`;

export const CardBottomInfo = styled.div`
    display: flex;
    flex-direction: row;
    
    text-align: center;

    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
`;

export const CardIconContainer = styled.div`
    width: 80%;
    min-width: 220px;
    margin-top: 10px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media screen and (max-width: 768px) {
        width: 100%;

        align-items: baseline;
    }
`;

export const CardBtn = styled(Button)`
    && {
        width: 100%;
        height: 35px;
        color: #fff;
        background-color: ${props => props.vitrinecolor};
        border-radius: 0;
        margin-top: auto;

        &:hover {
            background: ${props => darken(props.vitrinecolor, 0.1)};
        }

        a {
            color: #fff;
        }
    }
`;

export const CardIconLabel = styled.p`
    font-size: 16px;
    text-align: center;

    margin-top: 0;
    margin-bottom: 0;

    @media screen and (max-width: 1336px) {
        font-size: 14px;
        word-break: break-word;
    }

    @media screen and (max-width: 768px) {
        margin-top: 4px;
        word-break: normal;
    }
`;

export const CardIcon = styled.img`
    width: 30px;
    margin-bottom: 5px;

    @media screen and (max-width: 768px) {
        width: 25px;
        height: 28px;
    }
`;

export const CardFavoritar = styled.div`
    width: 50px;
    height: 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
`;

export const CardLink = styled(Link)`
    && {
        display: flex;

        justify-content: flex-end;
        margin-top: auto;

        @media screen and (max-width: 768px) {
            margin-left: auto;
        }
    }
`;

export const CardLogo = styled.img`
    width: 80px;
    height: 45px;

    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;

    object-fit: contain;
`;

export const CardButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
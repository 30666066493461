import React, { Component } from 'react';
import { HamburguerContainer, StripeTop, StripeMiddle, StripeBottom } from './hamburguer-styled';

export default class Hamburguer extends Component {
    constructor(props){
        super(props);

        this.toggle = this.toggle.bind(this);
    }

    toggle(e){
        this.props.onClick(e);
    }

    render() {
        return (
            <HamburguerContainer onClick={ this.toggle }>
                <StripeTop open={ this.props.open }></StripeTop>
                <StripeMiddle open={ this.props.open }></StripeMiddle>
                <StripeBottom open={ this.props.open }></StripeBottom>
            </HamburguerContainer>
        );
    }

}
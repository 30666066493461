import styled from 'styled-components';

export const CopyrightContainer = styled.div`
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    background-color: #2b043f;

    border-top: 2px solid #e7891a;

    @media screen and (max-width: 768px){
        overflow: hidden;
        flex-direction: column;
    }
`;

export const Text = styled.p`
    font-size: 14px;
    color: #FFF;
    padding: 5px 10px;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
`;

export const CopyrightUrls = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const UrlItem = styled.a`
    font-size: 14px;
    color: #FFF;
    padding: 5px 10px;
    border-right: 1px solid #FFF;

    &:last-child{
        border-right: 0;
    }

    @media screen and (max-width: 768px){
        text-align: center;
    }
`;
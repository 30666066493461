import React from 'react';
import styled from 'styled-components';

import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

export const PaginacaoContainer = styled.div`
    width: 100%;
    color: #FFF;
    background-color: ${props => props.vitrinecolor ? props.vitrinecolor : '#b1b1b1'};

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
`;

export const Resultados = styled.p`
    font-size: 16px;
    font-weight: bold;
    text-align: center;
`;

export const PaginasList = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const IconContainer = styled(IconButton)`
    &&{
        padding: 5px;
    }
`;

export const PrevBtn = styled(KeyboardArrowLeft)`
    &&{
        color: #FFF;
        border-radius: 50%;
    }
`;

export const NextBtn = styled(KeyboardArrowRight)`
    &&{
        color: #FFF;
        border-radius: 50%;
    }
`;

export const Item = styled(Button)`
    &&{
        color: #FFF;
        font-size: 16px;
        font-weight: ${props => props.active == 'true' ? 'bold' : null};

        text-decoration: ${props => props.active == 'true' ? 'underline !important' : null};

        &:hover{
            text-decoration: ${props => props.active == 'true' ? 'underline !important' : null};
        }

        @media screen and (max-width: 768px){
            min-width: initial;
            padding: 5px;
        }
    }
`;
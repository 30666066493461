import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fetchCategories } from "./../../actions";
import { UrlsClassiUtils } from "../../resources/methods/UrlsClassiUtils";

// Components
import {
    Container,
    VitrineTitulo,
    LogoVitrine,
    BannerContainer,
} from "./../../components/commons/theme-styled";
import GPTComponent from "./../../components/gpt/GPT";
import Loading from "../../components/loading/loading";
import Topbar from "./../../components/topbar/topbar";
import {
    sendAnalytics,
    enviarPageView,
} from "./../../components/analytics-gjc/analytics-gjc";
import { analyticsEvent } from "./../../components/analytics-gjc/analytics-gjc-mockup";
import { Helmet } from "react-helmet";
import BlogFeed from "../../components/blog-feed/blog-feed";

// Images
import LogoImoveis from "../../resources/svg/logos/logo-classi-agro.svg";
import BgImoveis from "../../resources/svg/bg-vitrines/fundo-agro.png";
import ClassiLogo from "./../../resources/svg/logo-classi.svg";

// Colors
import { Theme } from "../../components/commons/vitrine-colors";

// Styles
import {
    FilterContainer,
    FilterLabel,
    FilterInput,
    Filter,
    BuscaBtn,
    FilterSelect,
} from "./../../components/commons/filter-styled";
import { StringUtils } from "../../resources/methods/StringUtils";
import { initAnalytics } from "../../../helpers/analytics";

const vitrineType = process.env.VITRINE_TYPE.toLowerCase();

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            categorias: [],
            categoria: "",
            query: "",
            nextUrl: "#",
            vitrine: "agro",
            subsecao: "",
            showLoading: true,
        };

        this.selectSubsecao = this.selectSubsecao.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.analyticsBusca = this.analyticsBusca.bind(this);
    }

    componentDidMount() {
        this.props.fetchCategories();
        this.setState({ showLoading: false });

        // Evento Analytics
        initAnalytics("vitrine_agronegocio_capa", process.env.SITEID_ANALYTICS);
        enviarPageView();
    }

    componentDidUpdate(prevProps) {
        const { categoryList } = this.props;

        if (categoryList !== prevProps.categoryList) {
            this.setState({ categorias: categoryList });
        }
    }

    selectSubsecao(value) {
        this.setState({ subsecao: value.descricao });
    }

    analyticsBusca(e) {
        const texto = this.state.query,
            idCategoria = this.state.idCategoria;
        // Evento Analytics
        sendAnalytics(analyticsEvent.BUSCAR, {
            texto: texto ? texto : null,
            categoria: idCategoria,
        });
    }

    handleInput(e) {
        e.preventDefault();
        this.setState({ query: e.target.value });
    }

    handleCodigo(e) {
        e.preventDefault();
        this.setState({ codigo: e.target.value });
    }

    render() {
        const { query, showLoading } = this.state;

        return (
            <React.Fragment>
                <Helmet>
                    <title> Classi Agro </title>

                    {/* Google  */}
                    <meta property="title" content="Classi Agro" />
                    <meta property="type" content="website" />
                    <meta property="url" content={process.env.BASE_URL} />
                    <meta
                        property="image"
                        content={process.env.BASE_URL + ClassiLogo}
                    />
                    <meta
                        property="description"
                        content="Os melhores agronegócios estão no Classificados mais tradicional de Goiás. Encontre fazendas, chácaras, animais, máquinas e muito mais."
                    />
                    <meta property="site_name" content="Classi" />

                    {/* Open Graph data  */}
                    <meta property="og:title" content="Classi Agro" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={process.env.BASE_URL} />
                    <meta
                        property="og:image"
                        content={process.env.BASE_URL + ClassiLogo}
                    />
                    <meta
                        property="og:description"
                        content="Os melhores agronegócios estão no Classificados mais tradicional de Goiás. Encontre fazendas, chácaras, animais, máquinas e muito mais."
                    />
                    <meta property="og:site_name" content="Classi" />
                    <meta property="fb:admins" content="177140826180797" />

                    {/* Twitter */}
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:site" content="@classi_br" />
                    <meta name="twitter:title" content="Classi Agro" />
                    <meta
                        name="twitter:description"
                        content="Os melhores agronegócios estão no Classificados mais tradicional de Goiás. Encontre fazendas, chácaras, animais, máquinas e muito mais."
                    />
                    <meta name="twitter:creator" content="@classi_br" />
                    <meta
                        name="twitter:image:src"
                        content={process.env.BASE_URL + ClassiLogo}
                    />
                </Helmet>

                <Container direction="column" noPadding={true}>
                    <Topbar
                        withAnchor={false}
                        backgroundColor={Theme[vitrineType].main}
                    />

                    {showLoading && <Loading />}

                    {/* Intervenção */}
                    <GPTComponent gtpId="intervencao" gpt="intervencao" />

                    <Container
                        firstContainer={true}
                        direction="row"
                        justify="center"
                    >
                        <VitrineTitulo vitrinecolor={Theme[vitrineType].main}>
                            Encontre o imóvel dos seus sonhos.
                        </VitrineTitulo>
                    </Container>

                    <Container backgroundImg={BgImoveis} direction="row">
                        <LogoVitrine src={LogoImoveis} />

                        <FilterContainer direction="column">
                            <Filter noBorder direction="row">
                                <FilterLabel>Subseção: </FilterLabel>

                                <FilterSelect
                                    placeholder="Selecione a subseção"
                                    selection
                                    getOptionLabel={(option) =>
                                        option.descricao
                                    }
                                    options={this.state.categorias}
                                    vitrinecolor={Theme[vitrineType].main}
                                    onChange={(option) =>
                                        this.selectSubsecao(option)
                                    }
                                />
                            </Filter>

                            <Filter noBorder direction="row">
                                <FilterLabel>Palavra-chave: </FilterLabel>
                                <FilterInput
                                    placeholder="O que você procura?"
                                    value={this.state.query}
                                    onChange={this.handleInput}
                                />
                            </Filter>

                            <Filter noBorder direction="row">
                                <BuscaBtn
                                    buscacolor="#ffa000"
                                    to={{
                                        pathname: `/busca/${UrlsClassiUtils.constructFiltrosPathname(
                                            "",
                                            "",
                                            this.state.subsecao
                                        )}`,
                                        search: `?pagina=0${
                                            query ? "&query=" + query : ""
                                        }`,
                                    }}
                                    onClick={this.analyticsBusca}
                                >
                                    BUSCAR
                                </BuscaBtn>
                            </Filter>
                        </FilterContainer>
                    </Container>

                    <BannerContainer>
                        <GPTComponent gpt="small_maxiboard" />
                    </BannerContainer>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    categoryList: store.search.categoryList,
});

function loadData(store) {
    return store.dispatch(fetchCategories());
}

export default {
    loadData,
    component: withRouter(connect(mapStateToProps, { fetchCategories })(Home)),
};
